* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html, 
body{
  width: 100%;
  overflow-x: hidden;
} */
.Club-section {
  background-color: #000;

}

.own-bg {
  background: rgb(242, 238, 244);
  background: linear-gradient(90deg, rgba(242, 238, 244, 1) 17%, rgba(115, 73, 125, 1) 74%, rgba(56, 2, 62, 1) 100%);
}

@media (min-width: 768px) {}


.login-box {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  /* transform: translate(-50%, -50%); */
  background: rgba(0, 0, 0, 1);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
  text-align: center;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 25px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  text-align: center;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
  text-align: center;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label,
.login-box .user-box input:optional~label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
  text-align: center;
}

.login-box form .a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 4px
}

.login-box .a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
    0 0 25px #03e9f4,
    0 0 50px #03e9f4,
    0 0 100px #03e9f4;
  cursor: pointer;
}

.login-box .a span {
  position: absolute;
  display: block;
}

.login-box .a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%, 100% {
    left: 100%;
  }
}

.login-box .a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%, 100% {
    top: 100%;
  }
}

.login-box .a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%, 100% {
    right: 100%;
  }
}

.login-box .a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%, 100% {
    bottom: 100%;
  }
}

/*loading section */
.login-box form .loading {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #e91414;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 4px
}

.login-box .loading:hover {
  background: #e91414;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e91414,
    0 0 25px #e91414,
    0 0 50px #e91414,
    0 0 100px #e91414;
  cursor: not-allowed;
}

.login-box .loading span {
  position: absolute;
  display: block;
}

.login-box .loading span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #e91414);
  animation: btn-anim1-dis .45s linear infinite;
}

@keyframes btn-anim1-dis {
  0% {
    left: -100%;
  }

  50%, 100% {
    left: 100%;
  }
}

.login-box .loading span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #e91414);
  animation: btn-anim2-dis .45s linear infinite;
  animation-delay: .15s
}

@keyframes btn-anim2-dis {
  0% {
    top: -100%;
  }

  50%, 100% {
    top: 100%;
  }
}

.login-box .loading span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #e91414);
  animation: btn-anim3-dis .45s linear infinite;
  animation-delay: .3s
}

@keyframes btn-anim3-dis {
  0% {
    right: -100%;
  }

  50%, 100% {
    right: 100%;
  }
}

.login-box .loading span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #e91414);
  animation: btn-anim4-dis .45s linear infinite;
  animation-delay: .45s
}

@keyframes btn-anim4-dis {
  0% {
    bottom: -100%;
  }

  50%, 100% {
    bottom: 100%;
  }
}

/*disable section */
.login-box form .disable {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #777777;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 4px
}